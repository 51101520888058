import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GlobalStyles from './styles/GlobalStyles';
import AppContent from './components/AppContent';
import styled from 'styled-components';
import InstallBanner from './components/InstallBanner'; // Import the InstallBanner component

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AppWrapper>
          <GlobalStyles />
          <InstallBanner /> {/* Include the InstallBanner at the top */}
          <AppContent />
        </AppWrapper>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
