import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

function OAuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkAuth } = useAuth();
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const processAuthentication = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (token) {
        try {
          localStorage.setItem('token', token);
          await checkAuth();
		  window.location.href = '/dashboard';

          //navigate('/dashboard');

        } catch (error) {
          console.error('Authentication failed:', error);
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
      setIsProcessing(false);
    };

    processAuthentication();
  }, [location, navigate, checkAuth]);

  if (isProcessing) {
    return <div>Processing authentication...</div>;
  }

  return null;
}

export default OAuthCallback;