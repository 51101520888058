import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ConfirmationPending() {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Conferma la tua email
      </Typography>
      <Typography variant="body1" paragraph>
        Abbiamo inviato un'email di conferma al tuo indirizzo. 
        Per favore, controlla la tua casella di posta e clicca sul link di conferma.
      </Typography>
      <Typography variant="body2" paragraph>
        Non hai ricevuto l'email? Controlla la cartella spam o richiedi un nuovo link di conferma.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/resend-confirmation')}
      >
        Richiedi nuovo link
      </Button>
    </Box>
  );
}

export default ConfirmationPending;