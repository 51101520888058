import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/authUtils';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUnconfirmed, setIsUnconfirmed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  
   const deleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  };

  const getUsers = async () => {
    try {
      const response = await api.get('/users');
      return response.data.users;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  };

  const toggleUserActive = async (userId) => {
    try {
      await api.put(`/users/${userId}/toggle-active`);
    } catch (error) {
      console.error('Error toggling user active status:', error);
      throw error;
    }
  };

  const toggleUserAdmin = async (userId) => {
    try {
      await api.put(`/users/${userId}/toggle-admin`);
    } catch (error) {
      console.error('Error toggling user admin status:', error);
      throw error;
    }
  };
  
  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const response = await api.get('/verify-token');
            const user = response.data.user;

            // Check if the user is active
            if (!user.is_active) {
                throw new Error('User account is deactivated');
            }

            setUser(user);  // This should include `is_admin` and `is_active`
            console.log('User is admin:', user.is_admin);  // Log for debugging
            setIsAuthenticated(true);
        } catch (error) {
            console.error('Token verification failed or user is not active:', error);
            localStorage.removeItem('token');
            setUser(null);
            setIsAuthenticated(false);
        }
    } else {
        setIsAuthenticated(false);
        setUser(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    checkAuth();  // Run authentication check when the app starts
  }, [checkAuth]);

  const login = async (credentials) => {
    try {
      setLoading(true);
      const response = await api.post('/login', credentials);
      if (response.data.error_code === 'email_not_confirmed') {
        setIsUnconfirmed(true);
        throw { message: response.data.message, error_code: 'email_not_confirmed' };
      }
      localStorage.setItem('token', response.data.access_token);
      await checkAuth();  // Call checkAuth after setting the token
      window.location.reload();
      return response.data;
    } catch (error) {
      if (error.error_code === 'email_not_confirmed') {
        // Let the component handle this case
        throw error;
      }
      console.error('Login failed:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const register = async (userData) => {
    try {
      setLoading(true);
      const response = await api.post('/register', userData);
      // Do not set the token or authenticate the user immediately after registration
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await api.post('/logout');
    } catch (error) {
      console.error('Logout API call failed:', error);
    } finally {
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      setUser(null);
      setIsUnconfirmed(false);
      navigate('/login');
    }
  };

  const resendConfirmationEmail = async (email) => {
    try {
      setLoading(true);
      const response = await api.post('/resend-confirmation', { email });
      return response.data;
    } catch (error) {
      console.error('Resend confirmation email failed:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { 
    isAuthenticated, 
    isUnconfirmed, 
    loading, 
    user, 
    login, 
    logout, 
    register, 
    checkAuth,
    getUsers,
    toggleUserActive,
    toggleUserAdmin,
    resendConfirmationEmail,
	deleteUser
  };
}
