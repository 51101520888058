import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Container, Typography, Button, Box, Card, CardContent, Grid,
  CircularProgress, TextField, FormControlLabel, Checkbox, MenuItem,
  Dialog, DialogTitle, DialogContent, IconButton, List,
  ListItem, ListItemText, Snackbar, Alert, Fab, useMediaQuery, useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PrayerIcon from '@mui/icons-material/AccessibilityNew';
import CollectionIcon from '@mui/icons-material/LibraryBooks';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';




import api from '../utils/authUtils';
import { useAuth } from '../hooks/useAuth';



const collectionIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41],
});

const prayerIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [41, 41],
});

const createClusterCustomIcon = function (cluster) {
  const count = cluster.getChildCount();
  const size = Math.min(70, 40 + Math.log10(count) * 15);

  return L.divIcon({
    html: `<div class="cluster-icon">${count}</div>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(size, size, true),
  });
};

function StyledPopupContent({ title, subtitle, caption, children }) {
  return (
    <Box sx={{
      p: 1.5,
      bgcolor: '#fff',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      maxWidth: '280px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      border: '1px solid rgba(33, 102, 205, 0.2)',
    }}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 'bold',
          color: '#2166cd',
          mb: 0.1,
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        sx={{
          color: '#555',
          fontStyle: 'italic',
          fontFamily: 'Georgia, serif',
          mb: 0.1,
          lineHeight: 1,
        }}
      >
        {subtitle}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          color: '#777',
          fontSize: '12px',
          mb: 1,
        }}
      >
        {caption}
      </Typography>

      {children}
    </Box>
  );
}

function Dashboard() {
  const [uncollectedPrayers, setUncollectedPrayers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapZoom, setMapZoom] = useState(7);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const { logout } = useAuth();
  const [createdPrayers, setCreatedPrayers] = useState([]);
  const [collectedPrayers, setCollectedPrayers] = useState([]);
  const [content, setContent] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [category, setCategory] = useState('General');
  const [editPrayerId, setEditPrayerId] = useState(null);
  const [activeDialog, setActiveDialog] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedPrayer, setSelectedPrayer] = useState(null);
  const mapRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [collectingPrayerId, setCollectingPrayerId] = useState(null);
  const [collectMessage, setCollectMessage] = useState('');
  const [collectDialogOpen, setCollectDialogOpen] = useState(false);
  
  const [wantsToLeaveMessage, setWantsToLeaveMessage] = useState(false);



const fetchDashboardData = useCallback(async () => {
  setIsLoading(true);
  try {
    const [
      userResponse,
      prayersResponse,
      collectedResponse,
      prayersWithCollectionsResponse,
      uncollectedPrayersResponse,
    ] = await Promise.all([
      api.get('/dashboard'),
      api.get('/prayers'),
      api.get('/prayers/collected'),
      api.get('/prayers/collected-details'),
      api.get('/prayers/uncollected'),
    ]);

    setUserData(userResponse.data);
    setCreatedPrayers(prayersWithCollectionsResponse.data.prayers_with_collections || []);
    setCollectedPrayers(collectedResponse.data.collected_prayers || []);
    const uncollectedPrayersData = uncollectedPrayersResponse.data.prayers.map((prayer) => ({
      ...prayer,
      geo_location: prayer.geo_location ? JSON.parse(prayer.geo_location) : null,
    }));
    setUncollectedPrayers(uncollectedPrayersData);

    const firstPrayerWithLocation = prayersResponse.data.prayers.find(
      (prayer) =>
        prayer.geo_location &&
        JSON.parse(prayer.geo_location).coordinates &&
        JSON.parse(prayer.geo_location).coordinates.length >= 2
    );

    if (firstPrayerWithLocation) {
      const location = JSON.parse(firstPrayerWithLocation.geo_location);
      setMapCenter([location.coordinates[1], location.coordinates[0]]);
    }

    setDataFetched(true);
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    setError('Failed to load dashboard data. Please try again.');
    if (error.response && error.response.status === 401) {
      logout();
    }
  } finally {
    setIsLoading(false);
  }
}, [logout]);


  useEffect(() => {
    if (!dataFetched) {
      fetchDashboardData();
    }
  }, [dataFetched, fetchDashboardData]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      fetchDashboardData();
    }, 30000);

    return () => clearInterval(refreshInterval);
  }, [fetchDashboardData]);

  const handleCollectPrayer = useCallback(async () => {
  if (!collectingPrayerId) return;

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    await api.post(`/prayers/${collectingPrayerId}/collect`, {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      message: wantsToLeaveMessage ? collectMessage : '',  // Send the message if provided
    });

    setSnackbar({ open: true, message: 'Prayer collected successfully!', severity: 'success' });
    setDataFetched(false);
    setUncollectedPrayers((prevUncollected) =>
      prevUncollected.filter((prayer) => prayer.id !== collectingPrayerId)
    );
    setCollectDialogOpen(false);
    setCollectMessage('');
    setCollectingPrayerId(null);
    setWantsToLeaveMessage(false);
  } catch (error) {
    console.error('Error collecting prayer:', error);
    setSnackbar({
      open: true,
      message: 'Failed to collect prayer. Please try again.',
      severity: 'error',
    });
  }
}, [collectingPrayerId, collectMessage, wantsToLeaveMessage]);


  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!content) {
        setSnackbar({ open: true, message: 'Prayer content is required.', severity: 'error' });
        return;
      }

      setIsLoading(true);
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const endpoint = editPrayerId ? `/prayers/${editPrayerId}` : '/prayers';
        const method = editPrayerId ? 'put' : 'post';

        await api[method](endpoint, {
          content,
          is_anonymous: isAnonymous,
          category,
          latitude,
          longitude,
        });

        setSnackbar({
          open: true,
          message: `Prayer ${editPrayerId ? 'updated' : 'created'} successfully!`,
          severity: 'success',
        });
        setDataFetched(false);
        resetForm();
        setActiveDialog(null);
      } catch (err) {
        console.error('Error submitting prayer:', err);
        setSnackbar({ open: true, message: 'Failed to submit prayer.', severity: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    [content, isAnonymous, category, editPrayerId]
  );

  const resetForm = () => {
    setContent('');
    setIsAnonymous(false);
    setCategory('General');
    setEditPrayerId(null);
  };

  const handlePrayerClick = (prayer) => {
    setSelectedPrayer(prayer);
    if (prayer.geo_location) {
      try {
        const geoLocation = prayer.geo_location;
        if (geoLocation.coordinates && geoLocation.coordinates.length === 2) {
          const [lng, lat] = geoLocation.coordinates;
          setMapCenter([lat, lng]);
          setMapZoom(12);
          mapRef.current?.flyTo([lat, lng], 12);
        }
      } catch (error) {
        console.error('Error parsing geolocation:', error);
      }
    }
  };

  const uncollectedPrayersToDisplay = useMemo(() => {
    return uncollectedPrayers.filter((prayer) => prayer.user_id !== userData?.id);
  }, [uncollectedPrayers, userData]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setDataFetched(false)}>
          Retry
        </Button>
      </Container>
    );
  }

  return (
    <Box sx={{ position: 'relative', height: 'calc(100vh - 51px)', overflow: 'hidden' }}>
      <MapContainer
        center={mapCenter}
        zoom={mapZoom}
        style={{ height: '100%', width: '100%', zIndex: 1 }}
        zoomControl={false}
        ref={mapRef}
      >
        <ZoomControl position="bottomright" />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
          {uncollectedPrayersToDisplay.map(
            (prayer) =>
              prayer.geo_location &&
              prayer.geo_location.coordinates &&
              prayer.geo_location.coordinates.length >= 2 && (
                <Marker
                  key={prayer.id}
                  position={[
                    prayer.geo_location.coordinates[1],
                    prayer.geo_location.coordinates[0],
                  ]}
                  icon={prayerIcon}
                >
                  <Popup>
                    <StyledPopupContent
                      title={
                        prayer.is_anonymous
                          ? 'Anonymous Prayer'
                          : `Prayer by: ${prayer.username}`
                      }
                      subtitle={prayer.content}
                      caption={`Category: ${prayer.category || 'Uncategorized'}`}
                    >
                     <Button
					  variant="contained"
					  color="primary"
					  onClick={() => {
						setCollectingPrayerId(prayer.id);
						setSelectedPrayer({ ...prayer, collections: prayer.collections || [] }); // Ensure collections is an array
						setCollectDialogOpen(true);
					  }}
					  size="small"
					  sx={{
						alignSelf: 'flex-end',
						bgcolor: '#2166cd',
						color: '#fff',
						borderRadius: '12px',
						px: 2,
						py: 0.5,
						fontSize: '0.875rem',
						'&:hover': {
						  bgcolor: '#1b58a0',
						},
					  }}
					>
					  Collect
					</Button>


                    </StyledPopupContent>
                  </Popup>
                </Marker>
              )
          )}
        </MarkerClusterGroup>
      </MapContainer>

      {/* Trigger and Icons for Mobile */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 16,
          left: 16,
          zIndex: 3,
          display: { xs: 'flex', sm: 'none' },
          alignItems: 'center',
        }}
      >
        {/* Trigger Button */}
        <Fab color="primary" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <MenuIcon />
        </Fab>

        {/* Icons Container */}
        {mobileMenuOpen && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: 1, // Margin-left to space from the trigger button
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            {[
              { type: 'Created', icon: <PrayerIcon />, color: '#4CAF50' },
              { type: 'Collected', icon: <CollectionIcon />, color: '#2196F3' },
              { type: 'Add', icon: <AddIcon />, color: '#FF9800' },
            ].map(({ type, icon, color }, index) => (
              <Box key={type} sx={{ ml: index === 0 ? 0 : 0.5 }}>
                <Fab
                  size="small"
                  sx={{
                    backgroundColor: color,
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: color,
                    },
                  }}
                  onClick={() =>
                    setActiveDialog(type === 'Add' ? 'addPrayer' : type.toLowerCase() + 'Prayers')
                  }
                >
                  {icon}
                </Fab>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Container of the cards (only displayed on sm and up) */}
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          position: 'absolute',
          top: 120,
          left: 0,
          right: 0,
          padding: '10px',
          zIndex: 2,
          pointerEvents: 'none',
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            {/* Cards for Created Prayers, Collected, and Add */}
            {[
              { type: 'Created', icon: <PrayerIcon />, color: '#4CAF50' },
              { type: 'Collected', icon: <CollectionIcon />, color: '#2196F3' },
              { type: 'Add', icon: <AddIcon />, color: '#FF9800' },
            ].map(({ type, icon, color }) => (
              <Grid item xs={12} sm={3.8} key={type}>
                <Card
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    boxShadow: 4,
                    borderRadius: 2,
                    borderLeft: `4px solid ${color}`,
                    transition: 'transform 0.2s ease',
                    '&:hover': { transform: 'scale(1.03)' },
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    height: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() =>
                    setActiveDialog(type === 'Add' ? 'addPrayer' : type.toLowerCase() + 'Prayers')
                  }
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      padding: '8px !important',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {type !== 'Add' ? (
                        <>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 'bold',
                              color: color,
                              mr: 1,
                              lineHeight: 1,
                            }}
                          >
                            {type === 'Created'
                              ? userData?.prayers_count
                              : userData?.collected_prayers_count || 0}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 'bold',
                              color: 'text.secondary',
                              lineHeight: 1.2,
                            }}
                          >
                            Prayers
                            <br />
                            {type}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 'bold', color: color, lineHeight: 1.2 }}
                        >
                          Add
                          <br />
                          Prayer
                        </Typography>
                      )}
                      <Box sx={{ color: color, opacity: 0.7, ml: 'auto' }}>
                        {React.cloneElement(icon, { sx: { fontSize: 32 } })}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
<Dialog
  open={collectDialogOpen}
  onClose={() => {
    setCollectDialogOpen(false);
    setCollectMessage('');
    setWantsToLeaveMessage(false);
    setCollectingPrayerId(null);
  }}
>
<DialogTitle>
  Do you want to leave a message for {selectedPrayer?.username ? selectedPrayer.username : 'the author'}?
</DialogTitle>

  <DialogContent>
    {!wantsToLeaveMessage ? (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setWantsToLeaveMessage(true)}
          sx={{ mt: 2, mr: 2 }}
        >
          Yes, I want to leave a message
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCollectPrayer}
          sx={{ mt: 2 }}
        >
          No, collect without a message
        </Button>
      </>
    ) : (
      <>
        <TextField
          label="Your message"
          multiline
          rows={4}
          value={collectMessage}
          onChange={(e) => setCollectMessage(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCollectPrayer}
          sx={{ mt: 2 }}
        >
          Submit Message and Collect
        </Button>
      </>
    )}
  </DialogContent>
</Dialog>



      {/* Dialog for Created Prayers */}
      <Dialog
        open={activeDialog === 'createdPrayers'}
        onClose={() => setActiveDialog(null)}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '12px',
            overflow: 'hidden',
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #e0e0e0',
            padding: '16px 24px',
          }}
        >
          <Typography variant="h6">Map of Created Prayers</Typography>
          <IconButton onClick={() => setActiveDialog(null)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile
            padding: 0,
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              width: isMobile ? '100%' : '300px', // Full width on mobile
              borderRight: isMobile ? 'none' : '1px solid #e0e0e0',
              borderBottom: isMobile ? '1px solid #e0e0e0' : 'none',
              overflowY: 'auto',
              padding: '16px',
              backgroundColor: '#f5f7fa',
            }}
          >
<List>
  {createdPrayers.map((prayer) => (
    <ListItem
      key={prayer.id}
      button
      onClick={() => handlePrayerClick(prayer)}
      sx={{
        borderRadius: '4px',
        backgroundColor: 'white',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        padding: '12px 16px',
        '&:hover': {
          backgroundColor: '#f8f9fa',
        },
        flexDirection: 'column',  // Stack items vertically
        alignItems: 'flex-start',
      }}
    >
      <ListItemText
        primary={
          <Typography variant="subtitle1" sx={{ fontWeight: 500, color: '#202124' }}>
            {prayer.content}
          </Typography>
        }
        secondary={
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
            <FavoriteIcon sx={{ fontSize: 14, color: 'error.main', mr: 0.5 }} />
            <Typography variant="body2" sx={{ color: 'text.secondary', mr: 1 }}>
              {prayer.collections.length}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {prayer.category}
            </Typography>
          </Box>
        }
      />
      {/* Display collectors and their messages */}
      {prayer.collections.map((collection) => (
        <Box key={collection.collection_id} sx={{ mt: 1, pl: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Collected by: {collection.collector_username}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ChatBubbleOutlineIcon sx={{ fontSize: 16, mr: 1 }} />
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              {collection.message || 'No message left'}
            </Typography>
          </Box>
        </Box>
      ))}
    </ListItem>
  ))}
</List>


          </Box>
          {/* Conditionally render the map on mobile */}
          {!isMobile && (
            <Box sx={{ flex: 1, position: 'relative' }}>
              <MapContainer
                center={mapCenter}
                zoom={mapZoom}
                style={{ height: '100%', width: '100%' }}
                ref={mapRef}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
                  {createdPrayers.map((prayer) => {
                    if (prayer.geo_location) {
                      try {
                        const geoLocation = prayer.geo_location;
                        if (geoLocation.coordinates && geoLocation.coordinates.length === 2) {
                          const [lng, lat] = geoLocation.coordinates;
                          return (
                            <Marker
                              key={`prayer-${prayer.id}`}
                              position={[lat, lng]}
                              icon={prayerIcon}
                            >
                              <Popup>
                                <StyledPopupContent
                                  title={prayer.content}
                                  subtitle={`Category: ${prayer.category}`}
                                  caption={`Collections: ${prayer.collections.length}`}
                                />
                              </Popup>
                            </Marker>
                          );
                        }
                      } catch (error) {
                        console.error('Error parsing geolocation:', error);
                      }
                    }
                    return null;
                  })}
                  {selectedPrayer &&
                    selectedPrayer.collections.map((collection, index) => {
                      if (collection.geo_location) {
                        try {
                          const geoLocation = JSON.parse(collection.geo_location);
                          if (geoLocation.coordinates && geoLocation.coordinates.length === 2) {
                            const [lng, lat] = geoLocation.coordinates;
                            return (
                              <Marker
                                key={`collection-${selectedPrayer.id}-${index}`}
                                position={[lat, lng]}
                                icon={collectionIcon}
                              >
                                <Popup>
                                  <StyledPopupContent
                                    title={`Collected by: ${collection.collector_username || 'Anonymous'
                                      }`}
                                    subtitle={`Collected at: ${new Date(
                                      collection.collected_at
                                    ).toLocaleString()}`}
                                    caption={`Coordinates: [${lat.toFixed(5)}, ${lng.toFixed(5)}]`}
                                  />
                                </Popup>
                              </Marker>
                            );
                          }
                        } catch (error) {
                          console.error('Error rendering collection marker:', error);
                        }
                      }
                      return null;
                    })}
                </MarkerClusterGroup>
              </MapContainer>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Collected Prayers Dialog */}
      <Dialog
        open={activeDialog === 'collectedPrayers'}
        onClose={() => setActiveDialog(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Your Collected Prayers
          <IconButton
            aria-label="close"
            onClick={() => setActiveDialog(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {collectedPrayers.map((prayer) => (
              <ListItem key={prayer.id}>
                <ListItemText
                  primary={prayer.content}
                  secondary={`Category: ${prayer.category} | Author: ${prayer.author?.username || 'Anonymous'
                    } | Collected: ${new Date(prayer.collected_at).toLocaleString()}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      {/* Add Prayer Dialog */}
      <Dialog
        open={activeDialog === 'addPrayer'}
        onClose={() => setActiveDialog(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editPrayerId ? 'Edit Prayer' : 'Add New Prayer'}
          <IconButton
            aria-label="close"
            onClick={() => {
              setActiveDialog(null);
              resetForm();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}
          >
            <TextField
              placeholder="Enter your prayer here"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              multiline
              rows={4}
              required
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: '#ffffff',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#90caf9',
                  },
                  '&:hover fieldset': {
                    borderColor: '#42a5f5',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1e88e5',
                  },
                },
              }}
            />

            <TextField
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              variant="outlined"
              placeholder="Select a category"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: '#ffffff',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#90caf9',
                  },
                  '&:hover fieldset': {
                    borderColor: '#42a5f5',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1e88e5',
                  },
                },
              }}
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Health">Health</MenuItem>
              <MenuItem value="Family">Family</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>

            <FormControlLabel
              control={
                <Checkbox
                  checked={isAnonymous}
                  onChange={(e) => setIsAnonymous(e.target.checked)}
                />
              }
              label="Post as Anonymous"
            />

            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              {editPrayerId ? 'Update Prayer' : 'Submit Prayer'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Dashboard;
