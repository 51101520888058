import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f4f8; // Sfondo chiaro per contrasto
  color: #333;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  animation: ${fadeIn} 1s ease-out;
  align-items: center;
  @media (min-width: 768px) {
    padding: 8rem;
  }
`;

const ContentColumn = styled(Column)`
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const QuotesColumn = styled(Column)`
  background-color: #2166cd; // Colore di riferimento dell'app
  color: #ffffff;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2166cd; // Colore di riferimento dell'app
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
    text-align: center;
	
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
  max-width: 100%;
  margin-bottom: 2rem;
  line-height: 1.6;
  opacity: 0.8;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    max-width: 500px;
    margin-bottom: 3rem;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  width: 100%;
  max-width: 250px;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const CTAButton = styled(Button)`
  background-color: #2166cd; // Colore di riferimento dell'app
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(33, 102, 205, 0.3);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(33, 102, 205, 0.4);
    background-color: #1c59b3; // Versione leggermente più scura per hover
  }

  &::after {
    content: '→';
    margin-left: 0.5rem;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: #2166cd; // Colore di riferimento dell'app
  border: 2px solid #2166cd;

  &:hover {
    background-color: rgba(33, 102, 205, 0.1);
  }
`;

const QuoteContainer = styled.div`
  text-align: center;
  max-width: 100%;
  padding: 1rem;

  @media (min-width: 768px) {
    max-width: 400px;
    padding: 0;
  }
`;

const QuoteText = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const QuoteAuthor = styled.p`
  font-size: 0.9rem;
  opacity: 0.8;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const inspirationalQuotes = [
  {
    text: "La preghiera è il respiro dell'anima.",
    author: "San Giovanni Crisostomo"
  },
  {
    text: "Pregare significa lasciare vincere Dio.",
    author: "Carlo Carretto"
  },
  // Aggiungi altre citazioni come preferisci...
];

function LandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, loading } = useAuth();
  const [quote, setQuote] = useState({ text: '', author: '' });

  useEffect(() => {
    const randomQuote = inspirationalQuotes[Math.floor(Math.random() * inspirationalQuotes.length)];
    setQuote(randomQuote);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !loading && location.pathname !== '/dashboard') {
      navigate('/dashboard');
    }
  }, [isAuthenticated, loading, navigate, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <LandingWrapper>
      <ContentColumn>
        <Title>pray4all</Title>
        <Subtitle>
          Connect with people around the world through the power of prayer.
          Share your thoughts, support others, and experience the strength of a global spiritual community.
        </Subtitle>
        <ButtonContainer>
          <CTAButton to="/login?tab=register">
            Get Started
          </CTAButton>
          <SecondaryButton to="/login">Log In</SecondaryButton>
        </ButtonContainer>
      </ContentColumn>
      <QuotesColumn>
        <QuoteContainer>
          <QuoteText>"{quote.text}"</QuoteText>
          <QuoteAuthor>- {quote.author}</QuoteAuthor>
        </QuoteContainer>
      </QuotesColumn>
    </LandingWrapper>
  );
}

export default LandingPage;