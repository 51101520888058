import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, TextField, Button, Box, Typography, Grid, 
  Card, CardContent, IconButton, FormControlLabel, Checkbox, 
  MenuItem, CircularProgress, Snackbar, Alert, Divider, List, ListItem, ListItemIcon, ListItemText 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { useAuth } from '../hooks/useAuth'; 
import api from '../utils/authUtils';
import { useMediaQuery, useTheme } from '@mui/material';

function PrayersPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));  // Detect mobile screens
  
  const { isAuthenticated } = useAuth();
  const [createdPrayers, setCreatedPrayers] = useState([]);
  const [collectedPrayers, setCollectedPrayers] = useState([]);
  const [content, setContent] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [category, setCategory] = useState('General');
  const [editPrayerId, setEditPrayerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [activeSection, setActiveSection] = useState('Submit Prayer');

  const fetchPrayers = useCallback(async () => {
  setIsLoading(true);
  try {
    const createdResponse = await api.get('/prayers/user');
    const collectedResponse = await api.get('/prayers/collected');

    setCreatedPrayers(createdResponse.data.prayers || []);
    setCollectedPrayers(collectedResponse.data.collected_prayers || []);
  } catch (err) {
    console.error('Failed to load prayers:', err);
    setSnackbar({ open: true, message: 'Failed to load your prayers.', severity: 'error' });
    setCreatedPrayers([]);
    setCollectedPrayers([]);
  } finally {
    setIsLoading(false);
  }
}, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchPrayers();
    }
  }, [isAuthenticated, fetchPrayers]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (!content) {
      setSnackbar({ open: true, message: 'Prayer content is required.', severity: 'error' });
      return;
    }

    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const endpoint = editPrayerId ? `/prayers/${editPrayerId}` : '/prayers';
          const method = editPrayerId ? 'put' : 'post';
          
          await api[method](endpoint, { 
            content, 
            is_anonymous: isAnonymous, 
            category, 
            latitude, 
            longitude 
          });

          setSnackbar({ open: true, message: `Prayer ${editPrayerId ? 'updated' : 'created'} successfully!`, severity: 'success' });
          fetchPrayers();
          resetForm();
        } catch (err) {
          setSnackbar({ open: true, message: 'Failed to submit prayer.', severity: 'error' });
        } finally {
          setIsLoading(false);
        }
      },
      () => {
        setSnackbar({ open: true, message: 'Unable to fetch location. Please try again.', severity: 'error' });
        setIsLoading(false);
      }
    );
  }, [content, isAnonymous, category, editPrayerId, fetchPrayers]);

  const resetForm = () => {
    setContent('');
    setIsAnonymous(false);
    setCategory('General');
    setEditPrayerId(null);
  };

  const handleEdit = (prayer) => {
    setContent(prayer.content);
    setIsAnonymous(prayer.is_anonymous);
    setCategory(prayer.category);
    setEditPrayerId(prayer.id);
    setActiveSection('Submit Prayer');
  };

  const handleDelete = async (prayerId) => {
    try {
      await api.delete(`/prayers/${prayerId}`);
      setCreatedPrayers(createdPrayers.filter(prayer => prayer.id !== prayerId));
      setSnackbar({ open: true, message: 'Prayer deleted successfully!', severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: 'Failed to delete prayer.', severity: 'error' });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: '80px', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}> {/* Adjusted for fixed header */}
      {/* Conditional Rendering of Menu based on screen size */}
      <Box sx={{ width: isMobile ? '100%' : '20%', borderBottom: isMobile ? '1px solid #ddd' : 'none', borderRight: !isMobile ? '1px solid #ddd' : 'none', pr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}>
        <List sx={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column', justifyContent: isMobile ? 'space-around' : 'initial' }}>
          <ListItem 
            button 
            key="Submit Prayer" 
            onClick={() => setActiveSection('Submit Prayer')}
            selected={activeSection === 'Submit Prayer'}
            sx={{ flexGrow: isMobile ? 1 : 'unset' }}
          >
            <ListItemIcon>
              <AddCircleIcon />
            </ListItemIcon>
            {!isMobile && <ListItemText primary="Submit Prayer" />}
          </ListItem>
          
          <ListItem 
            button 
            key="Your Created Prayers" 
            onClick={() => setActiveSection('Your Created Prayers')}
            selected={activeSection === 'Your Created Prayers'}
            sx={{ flexGrow: isMobile ? 1 : 'unset' }}
          >
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            {!isMobile && <ListItemText primary="Your Created Prayers" />}
          </ListItem>

          <ListItem 
            button 
            key="Prayers You Collected" 
            onClick={() => setActiveSection('Prayers You Collected')}
            selected={activeSection === 'Prayers You Collected'}
            sx={{ flexGrow: isMobile ? 1 : 'unset' }}
          >
            <ListItemIcon>
              <CollectionsBookmarkIcon />
            </ListItemIcon>
            {!isMobile && <ListItemText primary="Prayers You Collected" />}
          </ListItem>
        </List>
      </Box>

      {/* Right-Side Content */}
      <Box sx={{ width: isMobile ? '100%' : '80%', pl: isMobile ? 0 : 3 }}>
        {activeSection === 'Submit Prayer' && (
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
            <Typography variant="h5">Submit a New Prayer</Typography>
<TextField
  placeholder="Enter your prayer here"
  value={content}
  onChange={(e) => setContent(e.target.value)}
  multiline
  rows={4}
  required
  variant="outlined"
  sx={{
    '& .MuiInputBase-root': {
      backgroundColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#90caf9',
      },
      '&:hover fieldset': {
        borderColor: '#42a5f5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1e88e5',
      },
    },
    '& .MuiInputLabel-root': {
      display: 'none', // Nasconde completamente la label
    },
  }}
/>

<TextField
  select
  value={category}
  onChange={(e) => setCategory(e.target.value)}
  variant="outlined"
  placeholder="Select a category"
  sx={{
    '& .MuiInputBase-root': {
      backgroundColor: '#ffffff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#90caf9',
      },
      '&:hover fieldset': {
        borderColor: '#42a5f5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1e88e5',
      },
    },
    '& .MuiInputLabel-root': {
      display: 'none', // Nasconde completamente la label
    },
  }}
>
  <MenuItem value="" disabled>
    Select a category
  </MenuItem>
  <MenuItem value="General">General</MenuItem>
  <MenuItem value="Health">Health</MenuItem>
  <MenuItem value="Family">Family</MenuItem>
  <MenuItem value="Other">Other</MenuItem>
</TextField>

            <FormControlLabel
              control={<Checkbox checked={isAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />}
              label="Post as Anonymous"
            />

            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              {editPrayerId ? 'Update Prayer' : 'Submit Prayer'}
            </Button>
          </Box>
        )}

        {activeSection === 'Your Created Prayers' && (
          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>Your Created Prayers</Typography>

            {isLoading ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={2}>
                {createdPrayers.length > 0 ? (
                  createdPrayers.map((prayer) => (
                    <Grid item xs={12} key={prayer.id}>
                      <Card sx={{ boxShadow: 3 }}>
                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box>
                            <Typography variant="h6">{prayer.content}</Typography>
                            <Typography variant="subtitle2">Category: {prayer.category}</Typography>
                            <Typography variant="caption" color="textSecondary">{new Date(prayer.created_at).toLocaleString()}</Typography>
                          </Box>
                          <Box>
                            <IconButton color="primary" onClick={() => handleEdit(prayer)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => handleDelete(prayer.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1">You haven't created any prayers yet.</Typography>
                )}
              </Grid>
            )}
          </Box>
        )}

{activeSection === 'Prayers You Collected' && (
  <Box>
    <Typography variant="h5" sx={{ mb: 2 }}>Prayers You Collected</Typography>

    {isLoading ? (
      <CircularProgress />
    ) : (
      <Grid container spacing={2}>
        {collectedPrayers && collectedPrayers.length > 0 ? (
          collectedPrayers.map((prayer) => (
            <Grid item xs={12} key={prayer.id}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Typography variant="h6">{prayer.content}</Typography>
                  <Typography variant="subtitle2">Category: {prayer.category}</Typography>
                  <Typography variant="subtitle2">Author: {prayer.author?.username || 'Anonymous'}</Typography>
                  {prayer.geo_location && (
                    <Typography variant="body2" color="textSecondary">
                      Location: {prayer.geo_location[1]}, {prayer.geo_location[0]}
                    </Typography>
                  )}
                  <Typography variant="caption" color="textSecondary">
                    Collected at: {new Date(prayer.collected_at).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1">You haven't collected any prayers yet.</Typography>
        )}
      </Grid>
    )}
  </Box>
)}
      </Box>

      {/* Snackbar for Feedback */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PrayersPage;