import { createGlobalStyle, keyframes } from 'styled-components';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f7fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
  }
  
  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .MuiTextField-root {
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  .custom-box-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .custom-marker-cluster {
    background: transparent;
    border: none;
  }
  
  .cluster-icon {
    background-color: #2166cd;
    color: white;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    box-shadow: 0 0 0 8px rgba(33, 102, 205, 0.2);
    transition: all 0.3s ease;
    
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 0 12px rgba(33, 102, 205, 0.3);
    }
  }
  
  .MuiDialog-paper {
    border-radius: 15px !important;
  }
  
  .MuiDialogContent-root {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  
  .dashboard-card {
    background-color: rgba(255, 255, 255, 0.9) !important;
    backdrop-filter: blur(10px) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 12px !important;
    transition: transform 0.2s ease !important;
    cursor: pointer !important;
    pointer-events: auto !important;
    height: 80px !important;
    
    &:hover {
      transform: scale(1.05) !important;
    }
  }
  
  .dashboard-card-content {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 100% !important;
    padding: 8px 16px !important;
  }
  
  .dashboard-card-count {
    color: #2166cd !important;
    margin-right: 1px !important;
    font-size: 2.5rem !important;
    font-weight: bold !important;
  }
  
  .dashboard-card-type {
    font-size: 1.3rem !important;
  }
  
  .dashboard-card-icon {
    font-size: 30px !important;
  }
  
  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: none !important;  /* Rimuove lo sfondo */
    box-shadow: none !important;  /* Rimuove l'ombra */
    border: none !important;  /* Rimuove eventuali bordi */
}

.leaflet-popup-content p {
    margin: 0.2em 0 !important;
}
  
  
`;

export default GlobalStyles;