import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useAuth } from '../hooks/useAuth';

function ResendConfirmation() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { resendConfirmationEmail } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resendConfirmationEmail(email);
      setMessage('Email di conferma reinviata. Controlla la tua casella di posta.');
    } catch (error) {
      setMessage('Errore nel reinvio dell\'email. Riprova pi� tardi.');
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Richiedi nuova email di conferma
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Invia
        </Button>
      </form>
      {message && (
        <Typography color="info" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
}

export default ResendConfirmation;