import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LandingPage from './LandingPage';
import AuthForm from './Login';
import Dashboard from './Dashboard';
import PrayersPage from './PrayersPage';
import Header from './Header';
import Footer from './Footer'; // Assicurati di importare il componente Footer
import { useAuth } from '../hooks/useAuth';
import { CircularProgress, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import EmailConfirmation from './EmailConfirmation';
import ConfirmationPending from './ConfirmationPending';
import ResendConfirmation from './ResendConfirmation';
import UserProfile from './UserProfile';
import OAuthCallback from './OAuthCallback';
import AdminPanel from './AdminPanel';

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
`;

function AppContent() {
  const { isAuthenticated, isUnconfirmed, loading, user } = useAuth();
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const isLoginPage = location.pathname === '/login';

  return (
    <ContentWrapper>
      {!isLandingPage && !isLoginPage && <Header />}
      <MainContent>
        {loading ? (
          <Box 
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CircularProgress size={60} sx={{ mb: 2 }} />
            <Box sx={{ width: '80%', mt: 2 }}>
              <Skeleton variant="rectangular" height={100} />
              <Skeleton variant="text" sx={{ mt: 2 }} />
              <Skeleton variant="text" />
            </Box>
          </Box>
        ) : (
          <Routes>
            <Route path="/admin" element={
              isAuthenticated && user.is_admin ? <AdminPanel /> : <Navigate to="/dashboard" />
            } />
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={
              isAuthenticated ? <Navigate to="/dashboard" /> : <AuthForm isUnconfirmed={isUnconfirmed} />
            } />
            <Route path="/dashboard" element={
              isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
            } />
            <Route path="/prayers" element={
              isAuthenticated ? <PrayersPage /> : <Navigate to="/login" />
            } />
            <Route path="/confirm/:token" element={<EmailConfirmation />} />
            <Route path="/confirmation-pending" element={<ConfirmationPending />} />
            <Route path="/resend-confirmation" element={<ResendConfirmation />} />
            <Route path="/oauth-callback" element={<OAuthCallback />} />
            <Route path="/profile" element={
              isAuthenticated ? <UserProfile /> : <Navigate to="/login" />
            } />
          </Routes>
        )}
      </MainContent>
      {!isLandingPage && !isLoginPage && <Footer />}
    </ContentWrapper>
  );
}

export default AppContent;
