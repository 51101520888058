import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography,
  Switch, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../hooks/useAuth';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { getUsers, toggleUserActive, toggleUserAdmin, deleteUser } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleToggleUserActive = async (userId, currentStatus) => {
    try {
      await toggleUserActive(userId);
      setUsers(users.map(user => 
        user.id === userId ? { ...user, is_active: !currentStatus } : user
      ));
    } catch (error) {
      console.error('Error toggling user active status:', error);
    }
  };

  const handleToggleUserAdmin = async (userId, currentStatus) => {
    try {
      await toggleUserAdmin(userId);
      setUsers(users.map(user => 
        user.id === userId ? { ...user, is_admin: !currentStatus } : user
      ));
    } catch (error) {
      console.error('Error toggling user admin status:', error);
    }
  };

  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        await deleteUser(selectedUser.id);
        setUsers(users.filter(user => user.id !== selectedUser.id));
        setOpenDialog(false);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const openDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>User Management</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell align="center">Admin</StyledTableCell>
              <StyledTableCell align="center">Active</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell component="th" scope="row">{user.username}</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell align="center">
                  {user.is_admin ? 
                    <Tooltip title="Admin">
                      <AdminPanelSettingsIcon color="primary" />
                    </Tooltip> : 
                    <Tooltip title="User">
                      <PersonIcon color="action" />
                    </Tooltip>
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Switch
                    checked={user.is_active}
                    onChange={() => handleToggleUserActive(user.id, user.is_active)}
                    color="primary"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title={user.is_admin ? "Remove Admin" : "Make Admin"}>
                    <IconButton onClick={() => handleToggleUserAdmin(user.id, user.is_admin)}>
                      <AdminPanelSettingsIcon color={user.is_admin ? "primary" : "action"} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User">
                    <IconButton onClick={() => openDeleteDialog(user)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete user "{selectedUser?.username}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteUser} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminPanel;
