import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, CircularProgress } from '@mui/material';

function EmailConfirmation() {
  const { token } = useParams();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    fetch(`/api/auth/confirm/${token}`)  // Cambia l'URL per usare un percorso relativo
      .then(response => response.json())
      .then(data => {
        setStatus(data.message === 'Email confirmed successfully!' ? 'success' : 'error');
      })
      .catch(() => setStatus('error'));
  }, [token]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Email Confirmation
      </Typography>
      {status === 'loading' && <CircularProgress />}
      {status === 'success' && (
        <Typography>
          Your email has been successfully confirmed. You can now log in.
        </Typography>
      )}
      {status === 'error' && (
        <Typography color="error">
          There was an error confirming your email. Please try registering again.
        </Typography>
      )}
    </Container>
  );
}

export default EmailConfirmation;
