import React, { useEffect, useState } from 'react';
import { Button, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import { isIOS } from 'react-device-detect';

function InstallBanner() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [bannerVisible, setBannerVisible] = useState(false);

  const isInStandaloneMode = () =>
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true;

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);

      const dismissedAt = localStorage.getItem('installPromptDismissedAt');
      const now = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000;

      if (!dismissedAt || now - dismissedAt > oneDay) {
        setBannerVisible(true);
      }
    };

    if (isIOS) {
      if (!isInStandaloneMode()) {
        const dismissedAt = localStorage.getItem('installPromptDismissedAt');
        const now = new Date().getTime();
        const oneDay = 24 * 60 * 60 * 1000;

        if (!dismissedAt || now - dismissedAt > oneDay) {
          setBannerVisible(true);
        }
      }
    } else {
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (isIOS) {
      alert('To install this app, tap the Share icon and then "Add to Home Screen".');
      setBannerVisible(false);
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
      setBannerVisible(false);
    }
  };

  const handleCloseBanner = () => {
    setBannerVisible(false);
    localStorage.setItem('installPromptDismissedAt', new Date().getTime());
  };

  return (
    bannerVisible && (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          background: 'linear-gradient(45deg, #2166cd 30%, #1b58a0 90%)',
          color: '#ffffff',
          zIndex: 1300,
          padding: '16px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          animation: 'slideDown 0.5s ease',
          '@keyframes slideDown': {
            from: {
              transform: 'translateY(-100%)',
            },
            to: {
              transform: 'translateY(0)',
            },
          },
        }}
      >
        <IconButton
          onClick={handleCloseBanner}
          color="inherit"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Install Pray4All for a better experience
        </Typography>
        <Button
          variant="contained"
          onClick={handleInstallClick}
          startIcon={<GetAppIcon />}
          sx={{
            backgroundColor: '#ffffff',
            color: '#2166cd',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          Install App
        </Button>
      </Box>
    )
  );
}

export default InstallBanner;
