import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
});

// Interceptor per aggiungere il token alle richieste
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Sending request to:', config.url);
      console.log('With token:', token);
    } else {
      console.log('No token found in localStorage');
    }
    return config;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

async function refreshAuthToken() {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      console.log('Attempting to refresh token...');
      const response = await api.post('/refresh-token', { token: refreshToken });
      const newAccessToken = response.data.access_token;
      console.log('New access token received:', newAccessToken);
      localStorage.setItem('token', newAccessToken);
      api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
      console.log('Token refreshed successfully');
      return newAccessToken;
    } else {
      console.log('No refresh token available');
      throw new Error('No refresh token available');
    }
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return Promise.reject(error);
  }
}

function handleLogout() {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  console.log('Logging out and redirecting to login...');
  window.location.href = '/login';
}

// Interceptor per gestire le risposte e i refresh del token
api.interceptors.response.use(
  (response) => {
    console.log('Response received:', response.status, response.data);
    return response;
  },
  async (error) => {
    console.error('Response error:', error.response ? error.response.status : 'No response');
    
    if (error.response && error.response.data.error_code === 'email_not_confirmed') {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshAuthToken();
        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        handleLogout();
        return Promise.reject(refreshError);
      }
    }

    if (error.response && error.response.status === 401) {
      handleLogout();
    }

    return Promise.reject(error);
  }
);

export default api;