import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  background-color: #2166cd;
  border-top: 1px solid #e0e0e0;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.p`
  color: #fff;
  font-size: 0.9rem;
  margin: 0;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;

  &:hover {
    color: #e73c7e;
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterText>&copy; {new Date().getFullYear()} pray4all</FooterText>
      <FooterLinks>
        <FooterLink to="/privacy">Privacy Policy</FooterLink>
        <FooterLink to="/terms">Terms of Service</FooterLink>
      </FooterLinks>
    </FooterWrapper>
  );
}

export default Footer;