import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField, 
  Button, 
  Container, 
  Typography, 
  Box, 
  Avatar, 
  CircularProgress,
  Alert,
  Grid
} from '@mui/material';
import AsyncSelect from 'react-select/async';  // Correct import path
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import api from '../utils/authUtils';

function UserProfile() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await api.get('/profile');
      setProfile(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setIsLoading(false);
    }
  };

  const handleInputChange = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}`);
      return response.data.map(location => ({
        label: location.display_name,
        value: location.display_name
      }));
    } catch (error) {
      console.error('Error loading options:', error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put('/profile', profile);
      setIsEditing(false);
      fetchProfile();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!profile) {
    return <Alert severity="error">Unable to load profile data.</Alert>;
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 15 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={4}>
            <Avatar 
              src={profile.profile_picture_url || ''} 
              sx={{ width: 150, height: 150, mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h4" gutterBottom>
              {profile.full_name || 'User Profile'}
            </Typography>
            {isEditing ? (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="first_name"
                  label="First Name"
                  value={profile.first_name || ''}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="last_name"
                  label="Last Name"
                  value={profile.last_name || ''}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="bio"
                  label="Bio"
                  multiline
                  rows={4}
                  value={profile.bio || ''}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
                <div style={{ marginBottom: '20px' }}>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    placeholder="Search for location"
                    onChange={(selectedOption) => handleInputChange('location', selectedOption.value)}
                    styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                  />
                </div>
                <Box sx={{ mt: 2 }}>
                  <Button type="submit" variant="contained" color="primary">
                    Save Changes
                  </Button>
                  <Button onClick={() => setIsEditing(false)} sx={{ ml: 2 }}>
                    Cancel
                  </Button>
                </Box>
              </form>
            ) : (
              <>
                <Typography variant="body1" gutterBottom>Email: {profile.email}</Typography>
                <Typography variant="body1" gutterBottom>Username: {profile.username}</Typography>
                <Typography variant="body1" gutterBottom>Bio: {profile.bio || 'No bio provided'}</Typography>
                <Typography variant="body1" gutterBottom>Location: {profile.location || 'Not specified'}</Typography>
                <Button onClick={() => setIsEditing(true)} variant="contained" color="primary" sx={{ mt: 2 }}>
                  Edit Profile
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default UserProfile;
